
import { useState } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faGlobe, faBars} from '@fortawesome/free-solid-svg-icons';
import {faMessage, faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {  useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {URL_BASE_STATICS} from "../config";

const Headsite = () => {
    
    const { t, i18n } = useTranslation('lang');
    const navigate = useNavigate();

    const initialStateViews = { language: false, menuMobile: false }
    const [views, setViews] = useState(initialStateViews);
    const { language, menuMobile } = views;

    const handleCloseLanguagesWithAnimation = () => {
        const language = document.querySelector('.header_top-bar_languages');
        language.classList.toggle('slideToDownLanguages');
        language.addEventListener('animationend', () => setViews({...initialStateViews}));
    }

    const handleCloseMainMenuWithAnimation = () => {
        const mainMenu = document.querySelector('.header_mobile-nav');
        const closeMenuFrame = document.querySelector('.header_close-menu');
        closeMenuFrame.classList.toggle('fadeOut');
        mainMenu.classList.toggle('slideMenuToRight');
        mainMenu.addEventListener('animationend', () => setViews({...initialStateViews}));
        document.querySelector('html').style.overflowY = 'scroll';
    }

    const handleChangeLangUi = (lang) => {
        i18n.changeLanguage(lang).then( () => localStorage.setItem('lang', lang) ).catch( err => console.log(err)); 
    }

    const handleNavigate = (route) => {
        document.querySelector('html').style.overflowY = 'scroll';
        setViews({...initialStateViews, menuMobile:false});
        navigate(route);
    }

    const renderMenuItems = () => {
        return(
            <>
                <li onClick={() => handleNavigate('/services/marketing-digital')}>{t('marketing-digital')}</li>
                <li onClick={() => handleNavigate('/services/design')}>{t('design')}</li>
                <li onClick={() => handleNavigate('/services/development')}>{t('dev')}</li>
                <li onClick={() => handleNavigate('/services/digital-content')}>{t('digital-content')}</li>
                <li onClick={() => handleNavigate('/services/foreign-trade')}>{t('foreign-trade')}</li>
                <li onClick={() => handleNavigate('/services/financial-services')}>{t('financial-services')}</li>
                <li onClick={() => handleNavigate('/contact-us')}>{t('contact')}</li>  
            </>
        )
    }

    return (
        <header>

            <div className="header_top-bar">
                <div className="header_top-bar_item">
                    <FontAwesomeIcon icon={faMessage} className="header_top-bar_item_svg" />
                    <span>+598 2518 2480</span>
                </div>

                <div className="header_top-bar_item">
                    <FontAwesomeIcon icon={faEnvelope} className="header_top-bar_item_svg"/>
                    <span>tilventis@tilventis.com.uy</span>
                </div>

                <div className="header_top-bar_item" onMouseOver={() => setViews({...initialStateViews, language: true})} >
                    <FontAwesomeIcon icon={faGlobe} className="header_top-bar_item_svg"/>
                    <FontAwesomeIcon icon={faCaretDown} className="header_top-bar_item_svg-caret"/>
                </div>
                
                {
                    language &&
                    <ul className="header_top-bar_languages" onMouseLeave={handleCloseLanguagesWithAnimation}>
                        <li onClick={() => handleChangeLangUi('en')}><img src={`${URL_BASE_STATICS}/flag-uk.png`} alt="ingles"/> <span>{t('english')} </span></li>
                        <li onClick={() => handleChangeLangUi('es')}><img src={`${URL_BASE_STATICS}/flag-spain.png`} alt="español"/>  <span>{t('spanish')} </span></li>
                    </ul>
                }

            </div>

            <nav className="header_nav">
                <img src={`${URL_BASE_STATICS}/logo_tilventis.png`} alt="logo tilventis" className="header_nav_logo" onClick={() => navigate('/')} />
                <ul className="header_nav_main-menu">
                    {renderMenuItems()}
                </ul>   
            </nav>

            <div className="header_mobile-header" >
                <img src={`${URL_BASE_STATICS}/logo_tilventis.png`} alt="logo tilventis" onClick={ () => navigate('/')} />
                <FontAwesomeIcon icon={faBars} className="header_mobile-header_icon-menu" onClick={() => {
                    document.querySelector('html').style.overflowY = 'hidden';
                    setViews({...initialStateViews, menuMobile:true});
                }}/>
            </div>
            {
                menuMobile &&
                <nav className="header_mobile-nav">
                    <div className="header_mobile-nav_langs">
                        <img src={`${URL_BASE_STATICS}/flag-spain.png`} alt="español" onClick={() => handleChangeLangUi('es')} />
                        <img src={`${URL_BASE_STATICS}/flag-uk.png`} alt="ingles" onClick={() => handleChangeLangUi('en')} />
                    </div>
                    <ul>
                        {renderMenuItems()}
                    </ul>
                    <img src={`${URL_BASE_STATICS}/logo_tilventis.png`} alt="logo tilventis" className="header_mobile-nav_logo"/>
                </nav>
            }
            {menuMobile && <div className="headear_close-menu" onClick={handleCloseMainMenuWithAnimation}></div>}
        </header>
    )
}

export default Headsite;