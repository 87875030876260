import FormMail from "../components/FormMail";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ContactUs = () => {

    const {t} = useTranslation('lang');

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return(
        
        <main className="contact">
            <br />
            <section className="contact_container">
                <div className="contact_container_info">
                    <ul>
                        <div>
                            <li> <FontAwesomeIcon icon={faPhone}/> <span>+598 2518 2480</span></li>
                            <small>{t('date_contact')}</small>
                        </div>
                        <li style={{color:'var(--light-blue-2)'}} > <FontAwesomeIcon icon={faEnvelope}/> <span>tilventis@tilventis.com.uy</span></li>
                        <li> <FontAwesomeIcon icon={faLocationDot}/> <address>Ruta 8 Km 17.500 <br/> Edificio 500 Quantum Local 506A <br/> Montevideo, 91600 <br/> Uruguay</address></li>
                    </ul>
                </div>
                <div className="contact_container_form">
                    <h1>{t('contact-title_form')}</h1>
                    <FormMail/>
                </div>
            </section>
        </main>
        

    )
}

export default ContactUs;