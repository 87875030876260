
import { useParams } from "react-router-dom";
import ServiceBanner from "../components/ServiceBanner";
import { useTranslation } from "react-i18next";
import {URL_BASE_STATICS} from "../config";

const Service = () => {

    const {t} = useTranslation('lang');
    const {typeService} = useParams();

    return(
        <main>
            {
                typeService === 'marketing-digital'
                ?
                <ServiceBanner 
                    imgBanner={`${URL_BASE_STATICS}/marketing.png`}
                    imgAltBanner='marketing digital'
                    backgroundBanner={'rgb(255, 152, 0)'}
                    titleBanner={t('marketing-digital')}
                    descriptionBanner={t('marketing-digital-title')}
                    descriptionPage={t('marketing-digital-p')}
                >
                    <section className="service_marketing-section bg-lightblue">
                        <div>
                            <h2>{t('marketing-digital-useradq-title')}</h2>
                            <p>{t('marketing-digital-useradq-p')}</p>
                        </div>
                        <img src={`${URL_BASE_STATICS}/marketing-user-acq.png`} alt="adquicion de usuarios" />
                    </section>

                    <section className="service_marketing-section">
                        <img src={`${URL_BASE_STATICS}/marketing-mobile.png`} alt="tecnologia móvil" />
                        <div>
                            <h2>{t('marketing-digital-mobiletecn-title')}</h2>
                            <p>{t('marketing-digital-mobiletecn-p')} <br /> {t('marketing-digital-mobiletecn-p2')} </p>
                        </div>
                    </section>

                    <section className="service_marketing-section bg-green">
                        <div>
                            <h2>{t('marketing-digital-branding-title')}</h2>
                            <p>{t('marketing-digital-branding-p') }</p>
                        </div>
                        <img src={`${URL_BASE_STATICS}/marketing-branding.png`} alt="branding" />
                    </section>

                </ServiceBanner>
                :
                typeService === 'design'
                ?
                <ServiceBanner
                    imgBanner={`${URL_BASE_STATICS}/ilustracion_disenio-380x380.png`}
                    imgAltBanner={'diseño'}
                    backgroundBanner={'rgb(251, 120, 20)'}
                    titleBanner={t('design')}
                    descriptionBanner={t('design-title')}
                >
                    <section className="service_design">
                        <article>
                            <h2>{t('desing-visual-contents')}</h2>
                            <p>{t('desing-visual-contents-p')}</p>
                        </article>

                        <article>
                            <h2>{t('design-web')}</h2>
                            <p>{t('design-web-p')}</p>
                        </article>

                        <article>
                            <h2>{t('design-brand')}</h2>
                            <p>{t('design-brand-p')}</p>
                        </article>

                        <article>
                            <h2>{t('design-animation2d')}</h2>
                            <p>{t('design-animation2d-p')}</p>
                        </article>

                        <article>
                            <h2>{t('design-social-media')}</h2>
                            <p>{ t('design-social-media-p') }</p>
                        </article>
                        
                    </section>
                </ServiceBanner>
                :
                typeService === 'development'
                ?
                <ServiceBanner
                    imgBanner={`${URL_BASE_STATICS}/web-380x304.png`}
                    imgAltBanner={'dev'}
                    backgroundBanner={'rgb(61, 156, 213)'}
                    titleBanner={t('dev')}
                    descriptionBanner={t('dev-title')}
                    descriptionPage = {t('')}
                >
                    <section className="services_dev-section">

                        <h2>{t('dev-specialize')}</h2>

                        <article>
                            <strong>{t('dev-web-dev')} </strong>
                            <p>{t('dev-web-dev-p')} </p>
                            <div>
                                <img src={`${URL_BASE_STATICS}/html.png`} alt="html5" />
                                <img src={`${URL_BASE_STATICS}/css.png`} alt="css" />
                                <img src={`${URL_BASE_STATICS}/javascript.png`} alt="javascript" />
                            </div>
                        </article>

                        <article>
                            <strong>{t('dev-mobile')}</strong>
                            <p>{t('dev-mobile-p')}</p>
                            <div>
                                <img src={`${URL_BASE_STATICS}/react-native-logo.png`} alt="react native" />
                                <img src={`${URL_BASE_STATICS}/kotlin.png`} alt="kotlin" />
                                <img src={`${URL_BASE_STATICS}/java.png`} alt="java" />
                                <img src={`${URL_BASE_STATICS}/swift.png`} alt="swift" />
                            </div>
                        </article>

                        <article>
                            <strong>{t('infrastructure')}</strong>
                            <p> {t('infrastructure-p')} </p>
                            <div>
                                <img src={`${URL_BASE_STATICS}/aws.png`} alt="amazon web services" />
                            </div>
                        </article>

                    </section>
                </ServiceBanner>
                :
                typeService === 'digital-content'
                ?
                <ServiceBanner
                    imgBanner={`${URL_BASE_STATICS}/digital-content.png`}
                    imgAltBanner={'contenido digital'}
                    backgroundBanner={'rgb(61, 156, 213)'}
                    titleBanner={t('digital-content')}
                    descriptionBanner={t('digital-content-title')}
                >
                    <section className="service_design">
                        <h2>{t('digital-content-services')}</h2>
                        <p> {t('digital-content-services-p')}</p>
                        <p>{t('digital-content-services-p3')}</p>
                        <p> {t('digital-content-services-p2')} </p>
                    </section>
                </ServiceBanner>
                :
                typeService === 'foreign-trade'
                ?
                <ServiceBanner
                    imgBanner={`${URL_BASE_STATICS}/com_exterior.png`}
                    imgAltBanner='foreign trade'
                    backgroundBanner={'rgb(32, 164, 168)'}
                    titleBanner={t('foreign-trade')}
                    descriptionBanner={t('foreign-trade-title')}
                    descriptionPage={t('foreign-trade-p')}
                    descriptionPage2={t('foreign-trade-p2')}
                >
                    <section className="service_foreign-trade">
                        <img src={`${URL_BASE_STATICS}/comercio_exterior_banner.jpg`} alt="foreign trade" />
                        <span>{t('foreign-trade-ul-title')}</span>
                        <ul>
                            <li>{t('foreign-trade-ul-li1')} </li>
                            <li>{t('foreign-trade-ul-li2')} </li>
                            <li>{t('foreign-trade-ul-li3')} </li>
                            <li>{t('foreign-trade-ul-li4')} </li>
                            <li>{t('foreign-trade-ul-li5')} </li>
                            <li>{t('foreign-trade-ul-li6')} </li>
                            <li>{t('foreign-trade-ul-li7')} </li>
                            <li>{t('foreign-trade-ul-li8')} </li>
                            <li>{t('foreign-trade-ul-li9')} </li>
                            <li>{t('foreign-trade-ul-li10')} </li>
                            <li>{t('foreign-trade-ul-li11')} </li>
                        </ul>
                    </section>
                </ServiceBanner>
                :
                typeService === 'financial-services'
                ?
                <ServiceBanner
                    imgBanner={`${URL_BASE_STATICS}/serv_financieros.png`}
                    imgAltBanner='foreign trade'
                    backgroundBanner={'#FF6633'}
                    titleBanner={t('financial-services')}
                    descriptionBanner={t('financial-services-title')}
                    descriptionPage={t('financial-services-p')}
                    descriptionPage2={t('financial-services-p2')}
                />
                :
                null
            }
        </main>
        
    )
}

export default Service;