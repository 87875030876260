import FormMail from "./FormMail"
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ServiceBanner = ({ 
    imgBanner, 
    imgAltBanner, 
    backgroundBanner, 
    titleBanner, 
    descriptionBanner, 
    descriptionPage, 
    descriptionPage2, 
    children 
}) => {

    useEffect(() => {
        window.scrollTo(0,0)
    },[imgBanner])

    const {t} = useTranslation('lang');
    return (
        <div className="service">
            <section style={{ backgroundColor: backgroundBanner }} className="service_banner" >
                <img src={imgBanner} alt={imgAltBanner} />
                <h1>{titleBanner}</h1>
                {descriptionBanner && <p>{descriptionBanner}</p>}
            </section>
            {
                descriptionPage && 
                <p className="service__p-1">{descriptionPage} {descriptionPage2 && <> <br /> {descriptionPage2}</>} </p>
            }
            {children}
            <section className="service_form">
                <h2>{t('lets_talk_bussines')}</h2>
                <FormMail/>
            </section>
        </div>
    )
}

export default ServiceBanner;