import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenRuler, faSuitcase, faShoppingCart} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {URL_BASE_STATICS} from "../config";

const Home = () => {

    const { t, i18n } = useTranslation('lang');
    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        
        <main>

            <section className="main_home-video">
                <video src={`${URL_BASE_STATICS}/reel_${i18n.language}.mp4`} loop autoPlay muted></video>
            </section>

            <section className="main_home-about-us">
                <div className="main_home-about-us_info">
                    <h1>{t('about_us_title')}</h1>
                    <p>{t('about_us_p')}</p>
                </div>
                <img src={`${URL_BASE_STATICS}/ilustracion_quienes-somos.png`} alt="quienes somos" />
            </section>

            <section className="main_home-other-services">

                <h1>{t('other_services_title')}</h1>

                <div className="main_home-other-services_container">

                    <div className="main_home-other-services_container_column">

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faPenRuler} className="icon-services"/>
                                <h2>{t('other_services_production_title')} </h2>
                            </div>
                            <p>{t('other_services_production_p')}</p>
                        </div>

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faSuitcase} className="icon-services redIcon"/>
                                <h2>{t('other_services_technical_title')}</h2>
                            </div>
                            <p>{t('other_services_technical_p')}</p>
                        </div>

                    </div>

                    <div className="main_home-other-services_container_column">

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faShoppingCart} className="icon-services redIcon" id="shopIcon"/>
                                <h2>{t('other_services_marketing_title')}</h2>
                            </div>
                            <p>{t('other_services_marketing_p')} </p>
                        </div>

                        <div className="main_home-other-services_container_column_service">
                            <div>
                                <FontAwesomeIcon icon={faSuitcase} className="icon-services"/>
                                <h2>{t('other_services_autation_title')} </h2>
                            </div>
                            <p>{t('other_services_autation_p')}</p>
                        </div>

                    </div>
                    
                </div>

            </section>
            
        </main>
        
    )
}

export default Home;