import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const {t} = useTranslation('lang');

    return (
        <footer>
            <div className="footer_copyright">
                <FontAwesomeIcon icon={faChevronUp} className="footer_copyright_to-up" onClick={() => window.scroll(0, 0)} />
                <span style={{ color: '#a1a3a4' }} >{t('copyright')}</span>
            </div>
            <a id="partner" className="footer_google-partner" href="https://www.google.com/partners/agency?id=6580521930" target="_blank" rel="noreferrer">
                <img src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg" alt="google partner"/>
            </a>
        </footer>
    )
}

export default Footer;